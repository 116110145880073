<template>
  <div :style="widthCss" @click="openImg" class="img-center">
    <div class="back-img-f">
      <img
        class="back-img"
        :src="firstData.imgs && firstData.imgs[0] ? firstData.imgs[0].img.url : require('@/assets/yy.jpg')"
        alt=""
      />
      <img
        class="back-img-b"
        :src="firstData.imgs && firstData.imgs[0] ? firstData.imgs[0].img.url : require('@/assets/yy.jpg')"
        alt=""
      />
    </div>
    <div class="card-img-title">
      <!-- <a-icon @click.stop="goToImg" type="more" /> -->
      <!-- <span @click.stop="goToImg">更多</span> -->
      <span @click.stop="goToImg" class="iconfont icon-xiao21 icon"></span>
    </div>
    <div class="todayText">
      <p style="padding-right:60px">{{ '「   ' + todayText.hitokoto }}」</p>
      <p v-if="todayText.from_who" style="text-align:right;padding-right:60px">{{ '— ' + todayText.from_who }}</p>
    </div>
    <a-modal width="700px" title="查看大图" :visible="visible" @cancel="handleCancel">
      <div class="max-img">
        <img
          class="back-img"
          :src="(firstData.imgs && firstData.imgs && firstData.imgs[0].img.url) || require('@/assets/yy.jpg')"
          alt=""
        />
        <img
          class="back-img-b"
          :src="firstData.imgs && firstData.imgs[0] ? firstData.imgs[0].img.url : require('@/assets/yy.jpg')"
          alt=""
        />
      </div>
      <div class="img-content">
        <div>
          {{ (firstData.imgs && firstData.imgs && firstData.imgs[0].img.title) || '默认图片' }}
        </div>
        <div class="user-content">
          <span>
            <img
              :src="firstData.imgs && firstData.imgs && firstData.imgs[0].upper && firstData.imgs[0].upper.avatar"
              style="width:21px;height:21px;border-radius:50%"
              alt=""
            />
            {{ firstData.imgs && firstData.imgs && firstData.imgs[0].upper && firstData.imgs[0].upper.realname }}
          </span>
          <span style="margin-left:30px">
            <a-icon
              @click="like(firstData.imgs && firstData.imgs[0])"
              type="like"
              :style="firstData.imgs && firstData.imgs && firstData.imgs[0].imgEvent.good ? 'color:' + color : ''"
            />
            <span>{{ (firstData.imgs && firstData.imgs && firstData.imgs[0].imgEventCount.good) || 0 }}</span>
          </span>
        </div>
      </div>
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          关闭
        </a-button>
        <a-button @click.stop="goToImg" type="primary">
          查看更多
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { putAction } from '@/api/manage'
export default {
  props: {
    firstData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      visible: false,
      todayText: {
        from_who: '',
        hitokoto: ''
      },
      color: '',
      slickWidth: '472px',
      slickHeight: '100%'
    }
  },
  created() {
    this.getText()
  },
  mounted() {
    this.color = this.$store.state.app.color
    setTimeout(() => {
      this.slickWidth = document.getElementsByClassName('img-center')[0].clientWidth + 'px'
    }, 0)
    window.onresize = () => {
      this.slickWidth = document.getElementsByClassName('img-center')[0].clientWidth + 'px'
    }
  },
  computed: {
    widthCss() {
      return {
        '--img-width': this.slickWidth,
        '--img-height': this.slickHeight
      }
    }
  },
  methods: {
    async like(item) {
      if (!item) return
      if (!item.imgEvent.good) {
        item.imgEvent.good = true
        item.imgEventCount.good = (item.imgEventCount.good || 0) - 0 + 1
      } else {
        item.imgEvent.good = false
        item.imgEventCount.good = (item.imgEventCount.good || 0) - 1
      }
      await putAction('imgCenterEvent/666/' + item.img.id)
    },
    async getText() {
      const res = await axios.get('https://v1.hitokoto.cn/?encode=json&lang=cn')
      if (res.status == 200) {
        this.todayText = res.data
      }
    },
    openImg() {
      this.visible = true
    },
    goToImg() {
      this.visible = false
      this.$router.push({
        path: '/system/imgCenter/?&name=图片广场'
      })
    },
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    }
  },
  watch: {
    firstData: {
      deep: true,
      handler(newVal) {
        if (newVal.imgs && newVal.imgs[0]) {
          let img = new Image()
          img.src = newVal.imgs[0].img.url
          img.onload = () => {
            let size = img.width / this.slickWidth.split('px')[0]
            this.slickHeight = img.height / size.toFixed(0) + 'px'
            if (img.width / size <= this.slickWidth.split('px')[0]) {
              this.slickHeight = 'auto'
            }
            if (img.width > img.height) {
              this.slickHeight = '100%'
            } else {
              this.slickWidth = '100%'
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.max-img {
  height: 450px;
  width: 600px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  .back-img {
    position: relative;
    z-index: 1;
    height: 100%;
    /* width: 100%; */
  }
  .back-img-b {
    position: absolute;
    width: 100%;
    filter: blur(15px);
  }
}
.img-center {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
  .back-img-f {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .back-img {
    position: relative;
    z-index: 1;
    height: 100%;
    /* width: 100%; */
  }
  .back-img-b {
    position: absolute;
    width: 100%;
    filter: blur(15px);
  }
}
@media (max-width: 1535px) {
  .img-center {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
    .back-img-f {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }
    .back-img {
      height: 100%;
      /* width: 100%; */
    }
  }
}
.todayText {
  position: absolute;
  top: 25px;
  left: 25px;
  color: #fff;
  font-size: 16px;
  width: 100%;
  z-index: 2;
  > p {
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 1);
  }
}
.card-img-title {
  padding: 12px 14px 12px 12px;
  display: flex;
  justify-content: end;
  span {
    z-index: 1;
    &:hover {
      color: var(--theme-color);
    }
  }
}
.anticon-more {
  z-index: 1;
  &:hover {
    color: var(--theme-color);
  }
}
.img-content {
  padding: 20px;
  /*   display: flex;
  justify-content: space-between; */
  > div {
    display: flex;
    justify-content: space-between;
    img {
      position: relative;
      top: -1px;
      margin-right: 3px;
    }
    .anticon-like {
      position: relative;
      top: -1px;
      margin-right: 5px;
      cursor: pointer;
    }
  }
  .user-content {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
  }
}
.icon-xiao21 {
  cursor: pointer;
  color: #fff;
  font-weight: 700;
  text-shadow: 0px 1px 5px #000000;
  &:hover {
    color: var(--theme-color) !important;
  }
}
</style>
